<template>
  <div class="myNote">
    <el-tabs v-model="active" @tab-click="handleClick">
      <el-tab-pane label="我的答疑"></el-tab-pane>
    </el-tabs>

    <div class="myNote_list" v-if="dataList.length">
      <div
        class="myNote_list_item"
        v-for="(item, index) in dataList"
        style="cursor:pointer;"
        :key="index"
        @click="getDaYiItem(item)"
      >
        <div style="display:flex;flex-direction:row;align-items:center;">
          <span
            style="
              width: 24px;
              height: 24px;
              background-color: #dd3535;
              border-radius: 3px;
              font-size: 16px;
              font-weight: 500;
              color: #ffffff;
              text-align:center;
              display:block;
            "
          >问</span>
          <span
            style="word-wrap: break-word; text-align: left;margin-left:15px;color:#333333;font-weight:bold;font-size:16px;"
          >{{ item.questionContent }}</span>
        </div>

        <!-- <div>
          <img
            style="width: 100px; height: 100px; margin-right: 10px"
            v-if="item3"
            :src="item3"
            alt
            v-for="(item3, index3) in item.questionPicList"
            :key="index3"
          />
        </div>-->
        <div class="myNote_list_item_text">{{ item.content }}</div>
        <div style="display:flex;flex-direction:row;align-items:center;">
          <span
            style="
              width: 24px;
              height: 24px;
              background-color: #4aa469;
              font-weight: 500;
              font-size: 16px;
              color: #ffffff;
              border-radius: 3px;
              text-align:center;
              display:block;
            "
            v-if="item.answerContent"
          >答</span>
          <span
            style="margin-left:15px;display:flex;align-items:center;color:#666666;font-size:14px;"
            class="answerTextBox"
            v-html="item.answerContent"
          ></span>
        </div>
        <div style="display:flex;flex-direction:row;align-items:center;">
          <span
            style="
              width: 24px;
              height: 24px;
              display:block;
            "
          ></span>
          <span class="org_text">{{item.courseName}}/{{item.classHourName}}</span>
        </div>
        <div class="myNote_list_item_footer">
          <p class="myNote_list_item_footer_left" style="width: 100%">
            <span
              style="
                flex: auto;
                text-align: right;
                color: #999999;
                font-size: 12px;
                font-family: SourceHanSansCN-Regular, SourceHanSansCN;
              "
            >{{ item.intime }}</span>
          </p>
        </div>
      </div>

      <div style="margin-top: 10px">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :page-size="10"
          layout="total, prev, pager, next"
          :total="count"
        ></el-pagination>
      </div>
    </div>
    <div class="noMore" v-else>
      <div class="coursediv_empty_img">
        <img src="@/assets/class_empty.png" alt />
      </div>暂无数据
    </div>
  </div>
</template>

<script>
import { wodedayi, notes_edit, notes_del } from "../../api/myself";
export default {
  name: "myDaYi",
  data() {
    return {
      active: 0,
      dataList: [],
      count: 0,
      page: 0
    };
  },
  mounted() {
    this.wodedayi();
  },
  methods: {
    getDaYiItem(item) {
      localStorage.setItem("daYiList", JSON.stringify(item));
      this.$router.push({
        path: "/myself/myDaYiList",
        query: {
          id: item.id
        }
      });
    },
    handleCurrentChange(val) {
      console.log(val);
      this.page = val - 1;
      this.wodedayi();
    },
    handleClick() {},
    wodedayi() {
      var obj = {
        page: this.page
      };
      wodedayi(obj).then(res => {
        console.log(res);
        this.dataList = res.data.data;
        this.count = res.data.count;
      });
    },

    del(e) {
      this.$confirm("确认要删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          notes_del({ noteId: e.id }).then(res => {
            if (res.data.code == 0) {
              this.mine_notes();
              this.$message.success("删除成功");
            } else {
              this.$message.error(res.data.msg);
            }
          });
        })
        .catch(() => {});
    }
  }
};
</script>

<style scoped>
.noMore {
  text-align: center;
  width: 100%;
  color: #999;
  font-size: 14px;
  margin: 40px 0;
}
</style>
<style lang="less" scoped>
.answerTextBox {
  /deep/ img {
    display: none;
  }
  /deep/ p {
    display: flex;
    align-items: center;
  }
}
.org_text {
  margin-top: 15px;
  margin-left: 15px;
  padding: 3.2px 8px;
  background: #f5f5f5;
  border-radius: 11px;
  color: #999;
  font-size: 12px;
  cursor: pointer;
  max-width: 418px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}
</style>